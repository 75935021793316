<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">{{ $t('heading.instance.advanced.users.title') }}</h4>
        </v-col>
        <v-col class="page-title__filter-controls">
          <data-iterator-filter-controls
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>
          <v-btn
            @click="showCreateModal"
            :large="!$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
          >
            <v-icon>$plus</v-icon>{{ $t('button.user.create') }}
          </v-btn>
          <v-dialog
            :persistent="modalOptions.persistent"
            v-model="modalOptions.open"
            ref="dialog"
            transition="custom-dialog-transition"
          >
            <div
              class="card-overlay"
              v-if="!modalOptions.persistent"
              @click="modalOptions.open = !modalOptions.open"
            />
            <div
              class="card-overlay"
              v-else
              @click="$refs.dialog.animateClick()"
            />
            <basic-modal
              style="width: 560px"
              :modalOptions="modalOptions"
              @modal-close="modalClose"
              :key="modalRender"
            />
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-0">
      <v-row>
        <v-col cols="12">
          <UsersTable
            :headers="headers"
            :items="items"
            :itemsLoading="itemsLoading"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            :page="page"
            :possibleItemsPerPage="[[8, 16, 24]]"
            :searchTerm="searchTerm"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            @update:searchTerm="handleSearchTermChange"
            @update:check-all="handleSelectAll"
            @update:checked-items="handleItemCheck"
            @update:itemsPerPage="changePerPage"
            @update:page="changePage"
            @update:sortBy="changeSortBy"
            @update:sortDesc="changeSortDesc"
            @action-button-edit="showEditModal"
            @action-button-reset-password="showResetPasswordModal"
            @action-button-delete="showDeleteModal"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import moment from "moment";

import DataIteratorPageMixin from "../../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../../mixins/CustomTablePageMixin";
import ActionModalMixin from "../../../mixins/ActionModalMixin";
import UsersActionsMixin from "../../../mixins/UsersActionsMixin";

import DataIteratorFilterControls from "../../../components/dataIterator/DataIteratorFilterControls.vue";
import UsersTable from "../../../components/dataIterator/tables/UsersTable.vue";
import BasicModal from "@/components/modal/BasicModal";

export default {
  components: {
    DataIteratorFilterControls,
    UsersTable,
    BasicModal,
  },
  mixins: [
    DataIteratorPageMixin,
    CustomTablePageMixin,
    ActionModalMixin,
    UsersActionsMixin,
  ],
  data: function () {
    return {
      sortBy: {
        text: 'id',
        align: "start",
        value: "id",
        sortable: true,
      },
      headers: [
        {
          text: 'id',
          align: "start",
          value: "id",
          sortable: true,
          searchable: true,
        },
        {
          text: 'login',
          value: "username",
          sortable: true,
          searchable: true,
        },
        {
          text: 'displayName',
          value: "display_name",
          sortable: true,
          searchable: true,
        },
        {
          text: 'email',
          value: "email",
          sortable: true,
          searchable: true,
        },
        {
          text: 'role',
          value: "role",
          sortable: true,
          searchable: true,
        },
        {
          text: 'registered',
          value: "user_registered",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      checkedItems: [],
      items: [],
      allItems: [],
      page: 1,
      itemsPerPage: 8,
      itemsLoading: true,
      searchTerm: "",
      itemsTotal: 0,
      fetching: true,
      instanceId: this.$route.params.id,
      modalRender: 0,
      modalOptions: {},
    };
  },
  mounted() {
    this.reloadItems();
  },
  methods: {
    reloadItems() {
      this.itemsLoading = true;
      Api.get(`/instances/${this.instanceId}/wordpress/users`).then(
        (response) => {
          this.itemsLoading = false;
          if (response.error) {
            // console.error("ERROR: ", response.error);
            return;
          }
          this.allItems = response.data.data.map((item) => {
            item.user_registered_text = moment(item.user_registered).fromNow();
            return item;
          });
          this.fetching = false;
          this.loadItems();
        }
      );
    },
    loadItems() {
      this.itemsLoading = true;
      let items = [...this.allItems];
      // filtering
      if (this.searchTerm) {
        items = items.filter((item) => {
          for (const header of this.headers) {
            if (header.searchable) {
              if (
                item[header.value]
                  .toString()
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())
              ) {
                return true;
              }
            }
          }
          return false;
        });
      }
      this.itemsTotal = items.length;
      // sorting
      items = items.sort((i1, i2) => {
        return (
          i1[this.sortBy.value]
            .toString()
            .localeCompare(i2[this.sortBy.value].toString()) *
          (this.sortDesc ? -1 : 1)
        );
      });
      // paging
      items = items.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
      this.items = items;
      this.itemsLoading = false;
    },
    changePage(page) {
      this.page = page;
      this.loadItems();
    },
    changePerPage(perPage) {
      this.page = 1;
      this.itemsPerPage = perPage;
      this.loadItems();
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.sortBy = data;
      this.loadItems();
    },
    changeSortDesc: function (data) {
      this.page = 1;
      this.sortDesc = data;
      this.loadItems();
    },
    handleSearchTermChange: function (newValue) {
      this.page = 1;
      this.searchTerm = newValue;
      this.loadItems();
    },
  },
  watch: {
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>
